<script>
    import {Line, mixins} from 'vue-chartjs';

    const {reactiveProp} = mixins;

    export default {
        extends: Line,
        mixins: [reactiveProp],
        props: ['options'],
        data: () => ({
            defaultOptions: {
                responsive: true, maintainAspectRatio: false
            }
        }),
        mounted() {
            this.renderChart(this.chartData, {...this.defaultOptions, ...this.options})
        }
    }
</script>